@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.paragraphs-item-hp-how-we-do-it {
  background: url(../../images/cross_background.png);
  @include clearfix;

  padding: 30px 0 26px;
  @include breakpoint($desktop) {
    padding: 60px 0 60px;
  }

  .ds-body-top {
    background: white;
    padding: 14px;
    margin-bottom: 30px;
    @include breakpoint($desktop) {
      padding: 30px;
      margin-bottom: 0;
    }
    border: 1px solid #d4cec5;
    p {
      margin: 0;
    }
  }

  .owl-carousel {
    @include breakpoint($mobile) {
      display: block;
    }
  }

  .ds-body-bottom {
    position: relative;
  }

  .field-name-field-title, .field-name-field-main-title {
    color: $color-gold;
    font-family: $font-headings;
    font-size: 30px;
    text-transform: uppercase;
    padding-bottom: 12px;
  }
  .field-name-field-body {
    line-height: 1.4em;
    p:first-child {
      font-size: 19px;
    }
  }

  // The section slider holder
  .paragraphs-items-field-sub-paragraphs-full {
    @include breakpoint($desktop-full) {
      float: left;
      width: calc(100% - 220px);
      margin-left: 20px;
    }
  }
}

.paragraphs-item-hp-how-we-do-it {
  // Mobile tabs
  .how-we-do-tab-mobile {
    display: block;
    @include breakpoint($desktop) {
      display: none;
    }

    font-family: $font-headings;
    color: $color-gold;
    font-size: 25px;
    padding: 15px 18px;
    border: 1px solid #d4cec5;
    border-bottom: none;
    line-height: 1em;
    background: #f9f9f9;

    &:hover, &.chosen {
      background: linear-gradient(to right, $color-gold 0, $color-gold 9px, #f9f9f9 9px, #f9f9f9 100%);

      @include breakpoint($desktop) {
        background: linear-gradient(to right, $color-gold 0, $color-gold 9px, white 9px, white 100%);
      }
    }

    &:after {
      content: "\e816";
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      position: absolute;
      right: 10px;
    }
    &.chosen:after {
      content: "\e815";
    }
  }


  .how-we-do-tabs {
    @include clearfix;
    z-index: 34;
    column-count: 2;

    @include breakpoint($mobile) {
      display: none;
    }

    @include breakpoint($desktop) {
      display: block;
      column-count: 4;
      justify-content:space-between;
      margin-bottom: 20px;
      margin-top: 30px;
    }
    @include breakpoint($desktop-full) {
      column-count: 1;
      display: block;
      width: 200px;
      float: left;
    }
  }
  .how-we-do-tab {
    //display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint($desktop-full) {
      margin-bottom: 20px;
    }

    font-family: $font-headings;
    color: $color-gold;
    font-size: 25px;
    padding: 12px 18px;
    border: 1px solid #d4cec5;
    background: #f9f9f9;

    cursor: pointer;
    &:hover, &.chosen {
      background: linear-gradient(to right, $color-gold 0, $color-gold 9px, #f9f9f9 9px, #f9f9f9 100%);

      @include breakpoint($desktop) {
        background: linear-gradient(to right, $color-gold 0, $color-gold 9px, white 9px, white 100%);
      }
    }

    @include breakpoint($desktop) {
      background: white;
    }

    &:hover, &.chosen {
      background: linear-gradient(to right, $color-gold 0, $color-gold 9px, #f9f9f9 9px, #f9f9f9 100%);

      @include breakpoint($desktop) {
        background: linear-gradient(to right, $color-gold 0, $color-gold 9px, white 9px, white 100%);
      }
    }
  }
}


.paragraphs-item-hp-how-we-do-it-sections:last-child .how-we-do-tab-mobile {
  border-bottom: 1px solid #d4cec5;

  &.chosen {
    border-bottom: none;
  }
}


// The individual slides
.paragraphs-item-hp-how-we-do-it-sections {

  // Hide all but first on mobile
  .inner-holder {
    display:none;
  }

  &:first-child .inner-holder {
    display: block;
  }

  .inner-holder {
    // Full width only
    @include breakpoint($desktop-full) {
      margin-top: 30px;
    }
    width: 100%;

    border: 1px solid #d4cec5;
    background: white;
    @include clearfix;
    position: relative;
    @include breakpoint($desktop) {
      height: 400px;
    }
  }

  .ds-group-left {
    padding: 15px;
    @include breakpoint($desktop) {
      width: 50%;
      float: left;
      padding: 32px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
  .ds-group-right {
    @include breakpoint($mobile) {
      max-width: 320px;
    }

    width: 100%;
    height: 310px;
    @include breakpoint($desktop) {
      width: 50%;
      height: 100%;
      float: left;
    }
    border-left: 1px solid #d4cec5;
    background-size: cover;
    background-position: center center;
  }
}
